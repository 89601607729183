<template>
  <div>
    MobileApp Mockup
  </div>

  <br>
  <div>
    <button @click="enterCloud('NOW')">CLOUD NOW - 입장하기</button>
  </div>

  <br>
  <div>
    <button @click="enterCloud('ON')">CLOUD ON - 입장하기</button>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import {entranceCloudNowOn} from "@/assets/js/modules/cloud/cloud-common";

export default {
  name: "MobileMockup",
  setup() {

    const store = useStore();
    const session = computed(() => store.state.auth.session)

    // 클라우드 입장 -> 차수 정보 등 하드코딩 처리
    const enterCloud = (cloudType) => {
      const params = {
        distCrseSn : cloudType === 'NOW' ? 200060 : 200088,
        objDistSn: cloudType === 'NOW' ? 181818 : 181922,
        objType: cloudType === 'NOW' ? 100008 : 100020,
        lrnerId: session.value.lrnerId
      }
      entranceCloudNowOn(params)
    }

    return {
      enterCloud,
    }
  }
}
</script>